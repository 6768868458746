/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const TooltipWrapper = styled.div`
  position: absolute;
  z-index: 10;
  pointer-events: none;
  opacity: 1;
  visibility: visible;
  transition: opacity 200ms ease-in-out;
  ${({ visible }) =>
    !visible &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
  ${({ direction, distanceFromMouse }) =>
    (direction === 'right' || direction === 'left') &&
    css`
      transform: translate3d(${distanceFromMouse}px, -50%, 0);
    `}
  ${({ direction, distanceFromMouse }) =>
    (direction === 'top' || direction === 'bottom') &&
    css`
      transform: translate3d(-50%, ${distanceFromMouse}px, 0);
    `};
`

const TooltipChildren = styled.div`
  * {
    font-family: 'Inter', sans-serif !important;
  }
`

const Tooltip = ({
  parent,
  direction,
  distanceFromMouse,
  visible,
  children,
  ...props
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const tooltipRef = useRef()
  const childRef = useRef()

  let running = false

  const onMouseMove = (e) => {
    if (!running) {
      running = true
      const rect = parent.getBoundingClientRect()
      setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top })

      setTimeout(() => {
        running = false
      }, 6)
    }
  }

  // Mount or parent change
  useEffect(() => {
    if (parent) {
      if (visible) {
        parent.addEventListener('mousemove', onMouseMove, false)
      } else {
        parent.removeEventListener('mousemove', onMouseMove, false)
      }

      return () => {
        parent.removeEventListener('mousemove', onMouseMove, false)
      }
    }
  }, [parent, visible])

  // Unmount
  useEffect(
    () => () => {
      if (parent) parent.removeEventListener('mousemove', onMouseMove)
    },
    []
  )

  const tooltipStyle = {}
  if (childRef && parent) {
    switch (direction) {
      case 'top':
        tooltipStyle.left = position.x
        if (
          !childRef.current ||
          position.y <= childRef.current.clientHeight * 1.2
        ) {
          tooltipStyle.top = position.y
        } else {
          tooltipStyle.bottom =
            parent.clientHeight - position.y + distanceFromMouse * 1.5
        }
        break

      case 'bottom':
        tooltipStyle.left = position.x
        if (
          !childRef.current ||
          position.y + childRef.current.clientHeight * 1.2 >=
            parent.clientHeight
        ) {
          tooltipStyle.bottom =
            parent.clientHeight - position.y + distanceFromMouse * 1.5
        } else {
          tooltipStyle.top = position.y
        }
        break

      case 'left':
        tooltipStyle.top = position.y
        if (
          !childRef.current ||
          position.x <= childRef.current.clientWidth * 1.2
        ) {
          tooltipStyle.left = position.x
        } else {
          tooltipStyle.right =
            parent.clientWidth - position.x + distanceFromMouse * 1.5
        }
        break

      case 'right':
      default:
        tooltipStyle.top = position.y
        if (
          !childRef.current ||
          position.x + childRef.current.clientWidth * 1.2 >= parent.clientWidth
        ) {
          tooltipStyle.right =
            parent.clientWidth - position.x + distanceFromMouse * 1.5
        } else {
          tooltipStyle.left = position.x
        }
        break
    }
  }

  return (
    <TooltipWrapper
      {...props}
      ref={tooltipRef}
      style={tooltipStyle}
      visible={visible}
      direction={direction}
      distanceFromMouse={distanceFromMouse}
    >
      <TooltipChildren ref={childRef}>{children}</TooltipChildren>
    </TooltipWrapper>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
  parent: PropTypes.node,
  visible: PropTypes.bool,
  direction: PropTypes.string,
  distanceFromMouse: PropTypes.number,
}

Tooltip.defaultProps = {
  children: null,
  parent: null,
  visible: false,
  direction: 'right',
  distanceFromMouse: 10,
}

export default Tooltip
